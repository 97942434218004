.speech-bubble {
  position: absolute;
  transform-origin: 0% 100%;
  text-align: center;
  background-color: rgba(90, 169, 255, 0.9);
  color: white;
  border-radius: 10px;
  width: 80%;
  padding: 10px;
  left: 255px;
  top: 85px;
  animation-fill-mode: forwards;
  animation-duration: 0.25s;
  z-index: 1000;
  max-width: 800px;
}

.speech-bubble-subscription {
  position: absolute;
  transform-origin: 0% 100%;
  text-align: center;
  background-color: rgba(90, 169, 255, 0.9);
  color: white;
  border-radius: 10px;
  width: 80%;
  padding: 10px;
  left: 255px;
  top: 400px;
  animation-fill-mode: forwards;
  animation-duration: 0.25s;
  z-index: 1000;
  max-width: 600px;
}

.speech-bubble-transporter {
  position: absolute;
  transform-origin: 0% 100%;
  text-align: center;
  background-color: rgba(90, 169, 255, 0.9);
  color: white;
  border-radius: 10px;
  width: 80%;
  padding: 10px;
  left: 255px;
  top: 205px;
  animation-fill-mode: forwards;
  animation-duration: 0.25s;
  z-index: 1000;
  max-width: 800px;
}

.speech-bubble-expand {
  animation-name: "expand-bounce";
}

.speech-bubble-shrink {
  animation-name: "shrink";
}

.speech-bubble::before {
  content: "";
  display: block;
  width: 0;
  position: absolute;
  top: 153px;
  left: -15px;
  border-style: solid;
  border-width: 15px;
  border-color: rgba(90, 169, 255, 0.9) transparent transparent rgba(90, 169, 255, 0.9);
  transform: rotate(315deg);
}

.speech-bubble-subscription::before {
  content: "";
  display: block;
  width: 0;
  position: absolute;
  top: 45px;
  left: -15px;
  border-style: solid;
  border-width: 15px;
  border-color: rgba(90, 169, 255, 0.9) transparent transparent rgba(90, 169, 255, 0.9);
  transform: rotate(315deg);
}

.speech-bubble-transporter::before {
  content: "";
  display: block;
  width: 0;
  position: absolute;
  top: -15px;
  left: 35px;
  border-style: solid;
  border-width: 15px;
  border-color: rgba(90, 169, 255, 0.9) transparent transparent rgba(90, 169, 255, 0.9);
  transform: rotate(45deg);
}

@media (max-width: 1436px) {
  .speech-bubble {
    left: 85px;
    background-color: rgba(90, 169, 255, 0.9);
  }
  .speech-bubble-subscription {
    left: 85px;
    background-color: rgba(90, 169, 255, 0.9);
  }
  .speech-bubble-transporter {
    left: 85px;
    background-color: rgba(90, 169, 255, 0.9);
  }
}

@media (max-width: 480px) {
  .speech-bubble {
    left: 85px;
    background-color: rgba(90, 169, 255, 0.9);
  }
  .speech-bubble-subscription {
    left: 85px;
    background-color: rgba(90, 169, 255, 0.9);
  }
  .speech-bubble-transporter {
    left: 85px;
    background-color: rgba(90, 169, 255, 0.9);
  }
}

@media (max-width: 419px) {
  .speech-bubble {
    width: 90%;
    top: 85px;
    left: 15px;
    background-color: rgba(90, 169, 255, 0.9);
  }

  .speech-bubble-subscription {
    width: 90%;
    top: 85px;
    left: 15px;
    background-color: rgba(90, 169, 255, 0.9);
  }

  .speech-bubble-transporter {
    width: 90%;
    top: 85px;
    left: 15px;
    background-color: rgba(90, 169, 255, 0.9);
  }

  .speech-bubble::before {
    scale: 0;
  }
  
  .speech-bubble-subscription::before {
    scale: 0;
  }

  .speech-bubble-transporter::before {
    scale: 0;
  }
}

@media (pointer:none), (pointer:coarse) {
  .speech-bubble {
    left: 85px;
    background-color: rgba(90, 169, 255, 0.9);
  }
  .speech-bubble-subscription {
    left: 85px;
    background-color: rgba(90, 169, 255, 0.9);
  }
  .speech-bubble-transporter {
    left: 85px;
    background-color: rgba(90, 169, 255, 0.9);
  }
}

@keyframes expand-bounce {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes shrink {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(0);
  }
}

.button-speech-bubble {
  border: none;
  position: relative;
  float: right;
  color: #525f80;
  font-size: 24px;
  background-color: rgba(0, 0, 0, 0);
}

.button-speech-bubble:hover {
  cursor: pointer;
}

.speech-link {
  text-decoration: none;
  color: #525f80;
}

.youtube-speech-bubble {
  border: none;
  position: relative;
  vertical-align:middle;
  color: #525f80;
  font-size: 24px;
  background-color: rgba(0, 0, 0, 0);
}

.youtube-speech-bubble:hover {
  cursor: pointer;
}

.speech-text {
  color: white;
  text-align: left;
}

.speech-title {
  color: white;
  padding-bottom: 30px;
  text-align: center;
}

.speech-content {
  margin: 20px 20px 20px 20px
}