
.main-content-header {
  display: flex;
  justify-content: space-between;
}

.main-content-header-left {
  width: auto;
}


.main-content-header-left-dashboard {
  display: flex;
  align-items: center;
}

.main-content-header-left-dashboard .custom-btn{
  margin-left: 20px;
  margin-top: 4px;
  padding-left: 30px;
  padding-right: 30px;
}

.main-content-header-statistic {
  list-style: none;
  display: flex;
  padding: 0;
  margin-bottom: 0;
}

.main-content-header-left-dashboard {
  display: flex;
  align-items: center;
}

.main-content-header-left-dashboard .custom-btn{
  margin-left: 20px;
  margin-top: 4px;
  padding-left: 30px;
  padding-right: 30px;
}

.main-content-header-statistic-item{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas: "value title" "value sup";
  margin-right: 30px;
}

.main-content-header-statistic-item-value {
  grid-area: value;
  font-size: 48px;
  line-height: 48px;
  color: var(--color-blue);
  text-align: right;
  padding-right: 10px;
  border-right: 1px solid var(--color-blue);
}

.main-content-header-statistic-item-title {
  grid-area: title;
  font-size: 18px;
  line-height: 22px;
  color: #5b626b;
  padding-left: 10px;

}

.main-content-header-title {
  font-family: var(--font-medium);
}

.main-content-header-statistic-item-sup {
  grid-area: sup;
  font-size: 12px;
  line-height: 14px;
  color: var(--color-blue);
  padding-left: 10px;
}

.main-content-header-search {
  width: 630px;
}

.main-content-header-search-add {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 900px;
}

.main-content-header-search-add .btn{
  flex: 0 0 auto;
  margin-left: 15px;
  padding-left: 30px;
  padding-right: 30px;
}

.main-content-header-search-stat {
  padding-bottom: 5px;
  font-size: 12px;
  line-height: 26px;
}

.dashboard-content-main {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: stretch;
}

.main-content-header-right .custom-btn-upload {
  font-family: var(--font-myriad-regular);
  padding-left: 19px;
  padding-right: 19px;
  margin-top: 20px;
}

.main-content-header-right-stretch {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  padding-top: 15px;
}

.main-content-header-right .main-content-header-btn{
  padding: 6px 26px;
  min-width: 170px;
  text-align: center;
  margin-top: 65px;
}

.main-content-header-right .btn + .btn {
  margin-left: 20px;
}


.dashboard-content-main .card:nth-child(odd) {
  /*width: 460px;*/
  width: 40%;
}

.dashboard-content-main .card:nth-child(even) {
  margin-left: 20px;
  width: calc(60% - 20px);
  /*width: 60%;*/
}


.dashboard-content .card {
  box-shadow: 0.629px -0.777px 35px 0px rgba(0, 0, 0, 0.16);
}

.custom-spinner-row {
  padding-top: 250px;
  margin-left: auto;
  margin-right: auto;
}

.modal-body-welcome-img {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.modal-body-welcome-text {
  text-align: center;
  padding: 30px 0;
  line-height: 30px;
  font-size: 18px;
  width: 100%;
}

.main-content-header-right-load {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-wrap: wrap;
  margin-left: 30px;
}

.main-content-header-right-load .select-car-model-error,
.main-content-header-right-load .parsePDFerror {
  width: 100%;
  text-align: right;
}

.main-content-header-right-load .custom-spinner {

}
