.board-table-btn-group-control {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.board-table-btn-group-control .board-table-btn-group-toggle {
  width: 76px;
  min-width: 76px;
  max-width: 76px;
}

.board-table-btn-group-control .board-table-btn-group-toggle input:checked ~ .board-table-btn-group-toggle-active {
  display: block;
  padding-left: 50%;
  height: 100%;
}

.board-table-btn-group-control .board-table-btn-group-toggle input:checked ~ .board-table-btn-group-toggle-notActive {
  display: none;
}

.board-table-btn-group-control .board-table-btn-group-toggle input ~ .board-table-btn-group-toggle-active {
  display: none;
}

.board-table-btn-group-control .board-table-btn-group-toggle input ~ .board-table-btn-group-toggle-notActive {
  display: block;
  padding-left: 8px;
}

.board-table-btn-group-control .board-table-btn-group-toggle-notActive,
.board-table-btn-group-control .board-table-btn-group-toggle-active {
  border: 1px solid var(--status-color-dark);
  background-color: #fafafa;
  border-radius: 30px;
  position: relative;
  z-index: 1;
  width: 100%;
  display: block;
  font-family: var(--font-myriadSemibold-regular);
  color: var(--status-color-dark);
  margin-bottom: 0;
  transition: all linear 0.25s;
}


.board-table-btn-group-control .board-table-btn-group-toggle label {
  position: relative;
  z-index: 1;
  width: 100%;
  display: block;
  margin-bottom: 0;
  height: 26px;
  cursor: pointer;
}

.board-table-btn-group-control .board-table-btn-group-toggle .board-table-btn-group-toggle-icon {
  position: absolute;
  top: 0px;
  right: 0px;
  margin: 2px;
  width: calc(50% - 4px);
  height: calc(100% - 4px);
  background-color: #4382F7;
  border-color: var(--status-color-dark);
  border-width: 3px;
  border-radius: 50%;
  z-index: 5;
  transition: all linear 0.25s;
}

.board-table-btn-group-control .board-table-btn-group-toggle input {
  display: none;
}

.board-table-btn-group-control .board-table-btn-group-toggle input:checked ~ .board-table-btn-group-toggle-icon {
  transform: translateX(-110%);

}

.board-table-btn-group-control .board-table-btn-group-toggle input:checked ~ .board-table-btn-group-toggle-active {
  color: var(--status-color-dark);
}

.board-table-btn-group-control .board-table-btn-group-icon {
  border-radius: 3px;
  border: 1px solid var(--mainColor);
}

.fas.fa-list::before {
  font-size: 12pt;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 8px;
}

.fas.fa-map::before {
  font-size: 12pt;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 8px;
}