.custom-card-help .card-body {
  display: flex;
}



.custom-card-help-right img {
  max-width: 100%;
  display: inline-block;
  margin: 0 auto;
}

.custom-card-help-left {
  font-size: 14px;
  line-height: 22px;
  padding-right: 50px;
  width: 65%;
}


.custom-card-help-right  {
  width: 35%;
  text-align: center;
  padding-top: 40px;
  padding-right: 10px;
}

.custom-card-help-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.custom-card-help-header .custom-btn {
  font-family: var(--font-myriadSemibold-regular);
  font-size: 14px;
}

.custom-card-help h5 {
  font-size: 18px;
}

.help-item-header {
  display: flex;
  justify-content: space-between;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding:  12px 20px;
  align-items: center;
  background: linear-gradient(to top, rgb(242,244,247) 0%, rgb(254,255,255) 100%);
  margin-top: 15px;
}

.help-item-header button {
  border: 1px solid var(--mainColor);
  color: var(--mainColor);
  border-radius: 3px;
  padding: 0;
  margin: 0;
  width: 34px;
  height: 34px;
  background-color: #fff;
  font-weight: bold;
  cursor: pointer;
}


.help-item-header button:before {
  content: '-';
}

.help-item-header button.collapsed:before {
  content: '+';
}

.help-item-title {
  font-weight: bold;
  font-size: 16px;
  font-family: var(--font-openSans-bold);
  color: #58595b;
}


.help-item-body {
  background-color: #f1f1f1;
  padding: 20px;
  font-size: 14px;
  line-height: 22px;
}

.custom-card-help-header h5 {
  font-size: 18px;
}


.help-item-body p,
.help-item-body ul li,
.help-item-body ol li {
  margin-bottom: 12px;
}
