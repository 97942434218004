.user-plan {
  width: 960px;
  margin: 0 auto;
  padding-top: 40px;
}

.user-plan-logo {
  margin: 0 auto;
  width: 91px;
  height: 68px;
  margin-bottom: 42px;
}

.user-plan-row {
  display: flex;
  justify-content: space-between;
}

.user-plan-col {
  width: calc(50% - 18px);
  border: 1px solid #c2c8d9;
  background-color: #fff;
  margin-bottom: 25px;
}

.user-plan-info h4 {
  text-align: center;
  text-transform: uppercase;
  font-size: 18px;
  padding-top: 30px;
  padding-bottom: 15px;
}

.user-plan-info p {
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 45px;
}

.user-plan-info-list {
  list-style-type: none;
  width: 85%;
  margin: 0 auto;
  margin-bottom: 35px;
}

.user-plan-info-list li {
  margin-bottom: 15px;
  font-weight: 500;
  font-size: 14px;
  font-family: var(--font-medium);
}

.user-plan-info-list li:last-child {
  margin-bottom: 0;
}

.user-plan-info-list i {
  color: var(--mainColor);
  margin-right: 18px;
}

.user-plan-info-drivers p {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 15px;
}

.user-plan-info-drivers-count {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 25px;
}

.user-plan-info-drivers-count .input-group,
.user-plan-info-drivers-count .custom-btn {
  width: 190px;
}

.user-plan-info-drivers-count .count-minus .input-group-text{
  border-top-left-radius: 3px !important;
  border-bottom-left-radius: 3px !important;
  border-right: none;
}

.user-plan-info-drivers-count .count-plus .input-group-text {
  border-left: none;
}

.user-plan-info-drivers-count .input-group-text {
  background-color: var(--mainColor);
  color: #fff;
}

.user-plan-info-sup {
  text-align: center;
  font-size: 11px;
  margin-bottom: 20px;
  line-height: 18px;
  padding-left: 20px;
  padding-right: 20px;
}

.user-plan-info-warn {
  text-align: center;
  font-size: 16px;
  margin-bottom: 20px;
  line-height: 18px;
  padding-left: 20px;
  padding-right: 20px;
}

.user-plan-info-warn strong {
  color: red;
}

.user-plan-info-sup.error {
  font-size: 13px;
}

.user-plan-info-drivers-count .form-control {
  background-color: #f5f5f5;
  text-align: center;
  font-weight: bold;
}

.user-plan-view-title {
  font-weight: 500;
  color: #fff;
  /* text-transform: uppercase; */
  font-size: 18px;
  text-align: center;
  padding: 20px 0;
  background-color: var(--mainColor);
  font-family: var(--font-medium);
}

.user-plan-view-list {
  padding: 32px 30px 35px 30px;
}

.user-plan-view-list p,
.user-plan-view-list span {
  padding: 0;
  margin: 0;
  font-family: var(--font-medium);

}


.user-plan-view-list li {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.user-plan-view-list li:not(:last-child) {
  border-bottom: 1px solid #bfbfbf;
  padding-bottom: 30px;
  margin-bottom: 30px;
  font-size: 18px;
  line-height: 26px;
  font-weight: 500;
  color: #5b626b
}

.user-plan-view-list li:last-child {
  font-weight: bold;
  color: #5b626b;
  font-size: 20px;
}

.user-plan-submit {
  display: flex;
  width: 260px;
  margin: 0 auto;
  justify-content: center;
}

@media(max-width: 968px) {
  .user-plan-row {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.user-plan-info .input-group-append {
  cursor: pointer;
}
