.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  text-align: center;
}

.loading-text {
  font-size: 16px;
  color: #333;
}

.route-map {
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.1);
}

@media (max-width: 1600px) {
  .route-map {
    max-width: 90%;
    max-height: auto;
  }
}