.nav-tabs-custom {
  justify-content: space-between;
}

.nav-tabs-custom-driver .nav-item {
  width: 50%;
  text-align: center;
}

.nav-tabs-custom > li > a.active {
  color: var(--mainColor) !important
}
.nav-tabs-custom > li > a:after {
  background: var(--mainColor)
}

.nav-tabs-custom > li > a::after {
  height: 3px;
}

.custom-driver-list {
  padding-left: 0;
  list-style: none;
  color: #3d4043;
}

.custom-driver-list-header {
  padding: 10px;
  display: flex;
}

.custom-driver-list-item {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px;
  border-bottom: 1px solid #ccc;
  font-size: 14px;
}

.custom-driver-list-item:hover {
  background-color: #f8f9fa;
}

.custom-driver-list-header span {
  text-align: center;
  font-size: 14px;
  color: var(--mainColor);
}

.custom-driver-list-header span:nth-child(1),
.custom-driver-list-item .custom-driver-list-item-img {
  width: 50px;
}

.custom-driver-list-header span:nth-child(2),
.custom-driver-list-item  .custom-driver-list-item-note {
  width: 80px;
  font-size: 14px;
}

.custom-driver-list-header span:nth-child(3),
.custom-driver-list-item  .custom-driver-list-item-name {
  width: calc(100% - 130px - 120px - 280px);
  font-size: 14px;
}

.custom-driver-list-header span:nth-child(4),
.custom-driver-list-item  .custom-driver-list-item-statusWrap {
  width: 120px;
  font-size: 14px;
}

.custom-driver-list-header span:nth-child(5),
.custom-driver-list-item  .custom-driver-list-item-price {
  width: 100px;
  font-size: 14px;
}

.custom-driver-list-header span:nth-child(6),
.custom-driver-list-item .custom-driver-list-item-fee {
  width: 80px;
  font-size: 14px;
}

.custom-driver-list-header span:nth-child(7),
.custom-driver-list-item .custom-driver-list-item-loads {
  width: 100px;
  font-size: 14px;
}

.custom-driver-list-header._footer span:nth-child(1) {
  width: 130px;
}
.custom-driver-list-header._footer span:nth-child(2) {
  width: 0px;
}

.custom-driver-list-item-img img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  display: block;
}

.custom-driver-list-item-name {
  font-size: 16px;
  text-align: center;
}

.custom-driver-list-item-note {
  font-size: 16px;
  text-align: center;
}

.custom-driver-list-item-status {
  position: relative;
  z-index: 1;
  padding-left: 20px;
  text-align: center;
}

.custom-driver-list-item-status:before {
  content: '';
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -6px;
}

.custom-driver-list-item-status-active:before{
  background-color: var(--status-color-green);

}
.custom-driver-list-item-status-assigned:before{
  background-color: var(--status-color-orange);

}
.custom-driver-list-item-status-notActive:before{
  background-color: var(--status-color-blue);
}

.custom-driver-list-item-status-deactivated:before {
  background-color: var(--status-color-dark);
}

.custom-driver-list-item-price,
.custom-driver-list-item-loads {
  text-align: center;
}

.custom-driver-list-item-price span,
.custom-driver-list-item-loads span{
  display: block;
  color: #5b5f66;
}

.custom-driver-list-item-loads {
  text-align: center;
}

.custom-pagination {
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.custom-pagination .page-item.active .page-link {
  background-color: var(--mainColor) !important;
  color: #fff;
}

.custom-pagination .page-item .page-link{
  color: var(--mainColor);

}


@media(min-width: 1600px) {

  .custom-driver-list-header span {
    font-size: 16px;
  }

  .custom-driver-list-header span:nth-child(1),
  .custom-driver-list-item .custom-driver-list-item-img {
    width: 50px;
  }

  .custom-driver-list-header span:nth-child(2),
  .custom-driver-list-item  .custom-driver-list-item-note {
    width: 100px;
    margin-left: 16px;
  }

  .custom-driver-list-header span:nth-child(3),
  .custom-driver-list-item  .custom-driver-list-item-name {
    width: calc(100% - 50px - 100px - 120px - 110px - 220px);
  }

  .custom-driver-list-header span:nth-child(4),
  .custom-driver-list-item  .custom-driver-list-item-statusWrap {
    width: 120px;
  }

  .custom-driver-list-header span:nth-child(5),
  .custom-driver-list-item  .custom-driver-list-item-price {
    width: 110px;
  }

  .custom-driver-list-header span:nth-child(6),
  .custom-driver-list-item .custom-driver-list-item-fee {
    width: 110px;
  }

  .custom-driver-list-header span:nth-child(7),
  .custom-driver-list-item .custom-driver-list-item-loads {
    width: 110px;
  }

  .custom-driver-list-header._footer span:nth-child(1) {
    width: 150px;
  }
  .custom-driver-list-header._footer span:nth-child(2) {
    width: 0;
  }
}

.people-table-comment-text .feeStyle {
  margin-left: 6px;
  font-size: 12px;
}
.custom-driver-list-item-fee .people-table-comment-control {
  text-align: center;
}

.people-table-header-filter._mydrivers {
  margin-left: 12px;
}

.arrowed {
  position: relative;
  margin-top: 4px;
}
.arrowed div {
  height: 0; width: 0;
  border: 8px solid;
  border-color: #c7c7c7 #c7c7c7 transparent transparent;
}
.arrowed .active {
  border-color: var(--mainColor) var(--mainColor) transparent transparent;
}

.arrow-next {
  transform: rotate(225deg);
  float: right;
}
.arrow-next:hover {
  cursor: pointer;
}
.arrow-prev {
  transform: rotate(45deg);
  float: left;
}
.arrow-prev:hover {
  cursor: pointer;
}

.nav-arrow {
  width: 29%;
}
.nav-center {
  width: 42%;
  text-align: center;
}
