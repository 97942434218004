.price-input-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.price-input-wrapper {
  display: flex;
  align-items: center;
  border: 1px solid #8A98AB;
  border-radius: 5px;
  padding: 10px;
  gap: 5px;
}

.price-input-wrapper-disabled {
  background-color: #f0f0f0;
  border-color: #ccc;
}

.price-input-label {
  margin-bottom: 5px;
  font-size: 16px;
}

.currency-symbol {
  margin-left: 8px;
  font-size: 18px;
  color: #4382F7;
}

.price-input {
  flex: 1;
  border: none;
  padding: 2px;
  font-size: 18px;
  outline: none;
  color: #4382F7;
  background-color: transparent;
}

.price-input-disabled {
  background-color: #f0f0f0;
  color: #999;
  cursor: not-allowed;
}

.price-input-icon {
  min-width: 24px;
  min-height: 24px;
  padding-left: 8px;
}

.price-input-text {
  font-size: 18px;
  color: #4382F7;
  padding-right: 10px;
}

.price-input-tip {
  font-size: 16px;
  color: #888;
  margin-top: 5px;
}