.date-input-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.date-input-icon-wrapper {
  display: flex;
  align-items: center;
  border: 1px solid #8A98AB;
  border-radius: 5px;
  overflow: hidden;
  padding-left: 10px;
}

.date-input-icon-wrapper-disabled {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
  padding-left: 10px;
  background-color: #f0f0f0;
  color: #333 !important;
  cursor: not-allowed;
}

.date-input:disabled {
  background-color: #f0f0f0;
  color: #999;
  cursor: not-allowed;
  border-color: #ccc;
}

.date-input-label {
  margin-bottom: 5px;
  font-size: 16px;
  color: #333;
}

.date-input {
  flex: 1;
  border: none;
  padding: 10px;
  font-size: 18px;
  outline: none;
  width: 100%;
  padding: 11px;
  border-radius: 6px;
  box-sizing: border-box;
}

.date-input-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  padding: 8px;
  margin-right: 8px;
  height: 100%;
}

.react-datepicker-popper {
  z-index: 100000 !important;
  padding-left: 4%;
  padding-top: 1.4%;
}

.data-placement {
  z-index: 100000 !important;
  position: absolute !important;
}