
.people-table-btn-group {
  display: flex;
  justify-content: center;
  border: 1px solid var(--mainColor);
  border-radius: 3px;
  /* width: 80px; */
  width: fit-content;
  margin: 0 auto;
}

.people-table-btn-group-single {
  width: 26px;
}

.people-table-btn-group-icon:not(:last-child) {
  border-right: 1px solid var(--mainColor);
}
.people-table-btn-group-icon {
  padding: 5px 5px;
  margin: 1px 1px;
  color: var(--mainColor);
  text-align: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  cursor: pointer;
}
.people-table-btn-group-icon:hover {
  color: var(--mainColorActive);
}

.people-table-btn-edit {
  font-size: 14px;
  color: #6e8195;
  cursor: pointer;
}

.people-table-btn-delete {
  color: #d33232;
  font-size: 14px;
  cursor: pointer;
}

.people-table-btn-edit-lock,
.people-table-btn-delete-lock {
  color: #c1c1c1;
  cursor: inherit;
}

.people-table-btn-edit-lock:hover,
.people-table-btn-delete-lock:hover {
  color: #c1c1c1;
}

.modal-body-delete {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 60px;
}

.modal-body-delete button {
  margin: 0 80px;
  font-family: var(--font-myriadSemibold-regular);
}

.modal-body-delete-text {
  text-align: center;
  line-height: 36px;
  font-size: 22px;
  width: 100%;
  padding: 40px 0 20px 0;
}


.custom-pagination .page-item [aria-disabled="true"] {
  color: #ccc;
}


.people-table table td.data-warning {
  color: #dc3545;
}

.load-table-header {
  list-style: none;
  display: flex;
  padding: 0;
  margin-bottom: 0;
  width: 100%;
}

.load-table-header.header-first > li:nth-child(2) {
  margin-left: 0px;
}

.load-table-header li {
  margin-right: 30px;
}
.load-table-header li.exportCSV {
  margin-right: 0;
}

.load-table-header.header-second li {
  margin-right: 0px;
}
.filter_checkbox {
  margin-right: 6px;
  margin-top: 6px;
}

.exportCSV {
  justify-content: flex-end;
  display: flex;
  flex: 1;
  align-items: flex-end;
}
.exportCSV:hover {
  cursor: pointer;
}

.export-csv-image {
  height: 26px;
  margin-top: -3px;
}

.change-name {
  cursor: pointer;
}

.upload-info-text,
.upload-info-text a {
  color: #EC5A53;
  size: 12px;
  line-height: 18px;
}

.upload-info-text {
  margin-bottom: 0;
  margin-right: 30px;
}

.upload-info-text a {
  font-weight: 700;
  text-decoration: underline;
}

.upload-info-text a:hover {
  color: #EC5A53;
  text-decoration: underline;
}

.export-list-text {
  font-weight: 700;
  height: 100%;
  size: 12px;
  line-height: 18px;
  color: #535455;
  margin-right: 5px;
}

.export-list-text:hover {
  text-decoration: underline;
  cursor: pointer;
}

.img-container {
  display: flex;
  align-items: flex-end;
}

.load_details_header {
  border-radius: 3px;
  border-width: 1px;
  border-color: dimgray;
  border-style: solid;
  text-align: center;
  padding: 7px;
  background-color: whitesmoke;
  color: black;
  margin-right: 10px;
  margin-bottom: 10px;
}

.load_details-btn {
  border-radius: 3px;
  border-width: 1px;
  border-style: solid;
  text-align: center;
  padding: 8px 12px 4px 12px;
  background: var(--mainColor);
  border-color: var(--mainColor);
  color: #fff;
  margin-right: 10px;
  margin-bottom: 10px;
  font-family: var(--font-myriadSemibold-regular);
  font-size: 14px;
}

.load_details-btn:hover {
  background: var(--mainColorHover);
  border-color: var(--mainColorHover);
  color: #fff;
  cursor: pointer;
}

.load_details-btn:active,
.load_details-btn:focus {
  background: var(--mainColorActive) !important;
  border-color: var(--mainColorActive) !important;
  color: #fff !important;
}

.car_detail_box {
  display: flex;
  flex-wrap: wrap;
}

.load_details_header:hover {
  background-color: gainsboro;
  cursor: pointer;
}

div.rowContainer {
  max-width: 100%;
  align-items: left;
  vertical-align: top;
  display: inline-block;
}

div.rowOuter {
  display: inline-block;
  padding-left: 0%;
  padding-right: 40px;
  padding-top: 10px;
  vertical-align: top;
}

div.rowOuter ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

div.rowOuter li:hover {
  cursor: pointer;
  /* text-shadow: 0 0 15px #c1c1c1, 0 0 10px #c1c1c1; */
  text-decoration: underline;
}

div.rowInner {
  display: inline-block;
  padding-left: 0%;
  padding-right: 40px;
  vertical-align: top;
  padding-top: 10px;
}

div.rowInner ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

div.rowInner li:hover {
  cursor: pointer;
  /* text-shadow: 0 0 15px #c1c1c1, 0 0 10px #c1c1c1; */
  text-decoration: underline;
}

div.rowLast {
  display: inline-block;
  padding-left: 0%;
  padding-right: 40px;
  vertical-align: top;
  padding-top: 10px;
}

div.rowLast ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

div.rowLast li:hover {
  cursor: pointer;
  /* text-shadow: 0 0 15px #c1c1c1, 0 0 10px #c1c1c1; */
  text-decoration: underline;
}

.rowTitle {
  font-weight: bold;
  color: lightslategray; 
  padding-left: 10px
}

.rowSubtitle {
  font-weight: bold;
  color: lightslategray; 
  cursor: auto !important;
  text-decoration: none !important;
  text-shadow: none !important;
}

div.date-difference {
  background-color: gainsboro !important;
  width: fit-content !important;
  display: flex;
  vertical-align: top;
  flex-direction: row;
  justify-content: center;
  padding: 16px 30px 16px 30px;
  border-radius: 4px;
}

div.date-title {
  font-size: 10pt;
  font-weight: bold;
}

div.full-date {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: fit-content;
  text-align: left;
  color: var(--mainColorActive)
}

div.date-day {
  text-align: left;
  padding: 0px !important;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font-weight: bold; 
}

div.date-day-of-month {
  text-align: left;
  font-size: 36pt;
  font-weight: bold; 
  line-height:normal;
}

div.date-month-year-status {
  text-align: left;
  font-size: 12pt;
  padding-top: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 6px;
}

div.date-month-year {
  text-align: left;
  font-size: 12pt;
  padding-top: 0px;
  font-weight: bold;
}

div.date-status {
  text-align: left;
  font-size: 10pt;
  padding-top: 2px;
  font-weight: bold;
  color: var(--mainColorActive) !important;
}

div.middle-date {
  display: inline-block;
  align-content: center;
  text-align: center;
  vertical-align: middle;
}

div.middle-date::before {
  display: inline-block;
  padding-left: 30px;
  padding-right: 30px;
  align-content: center;
  text-align: center;
  vertical-align: middle;
  padding-top: 28px;
  height: 60px;
  font-size: 28pt;
  color: var(--mainColorActive)
}

.notdelayed {
  color: #419c5f !important;
}

.data-warning {
  color: #dc3545 !important;
}