.table-top-clients td {
  color: #3d4043;
  font-size: 14px;
}

.custom-card-body .table thead th {
  font-size: 12px;
}

.table-top-clients thead th {
  font-family: var(--font-semibold);
  color: #3d4043;
}

.table-top-clients thead th:nth-child(1),
.table-top-clients tbody td:nth-child(1){
  width: 72px;
}

.table-top-clients thead th:nth-child(2),
.table-top-clients tbody td:nth-child(2){
  text-align: left;
}

.table-top-clients thead th:nth-child(3),
.table-top-clients tbody td:nth-child(3){
  width: 160px;
}

.table-top-clients thead th:nth-child(4),
.table-top-clients tbody td:nth-child(4) {
  width: 100px;
}
