.subscription-info {
  box-shadow: 0.629px -0.777px 35px 0px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  background-color: #fff;
  padding: 20px;
  width: 960px;
  margin: 0 auto;
}

.subscription-info p {
  color: #5b626b;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
}

.subscription-info-left span {
  font-size: 14px;
  color: #383838;
  font-family: var(--font-semibold);
}

.subscription-info-cancel {
  font-size: 14px;
  color: var(--mainColor);
  margin-top: 20px;
  cursor: pointer;
}

.modal-payment-list {
  list-style: none;
  padding: 0;
  margin: 0;
  padding-bottom: 60px;
}

.modal-payment-list-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 50px;
}

.modal-payment-list-item:last-child {
  margin-bottom: 0;
}

.modal-payment-list .custom-btn {
  font-family: var(--font-semibold);
}

.modal-payment-list-item-img {
  border: 1px solid  rgb(206, 208, 218);
  border-radius: 10px;
  background-image: -moz-linear-gradient( 90deg, rgb(242,244,247) 0%, rgb(254,255,255) 100%);
  background-image: -webkit-linear-gradient( 90deg, rgb(242,244,247) 0%, rgb(254,255,255) 100%);
  background-image: -ms-linear-gradient( 90deg, rgb(242,244,247) 0%, rgb(254,255,255) 100%);
  width: 150px;
  height: 78px;
  position: relative;
  z-index: 1;
  margin-right: 10px;
}

.modal-payment-list-item-img img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  margin: auto;
}


.modal-payment-list-item-name {
  font-size: 13px;
  color: #212224;
}

.modal-payment-list .custom-btn {
  margin-left: auto;
}

.stripeForm {
  padding: 50px 110px 20px 110px;
}

.stripeForm button {
  display: block;
  width: 260px;
  margin: 0 auto;
  height: 37px;
  background-color: var(--mainColor);
  border-radius: 2px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
  border: none;
  transition: all linear 0.2s;
  margin-top: 65px;
  margin-bottom: 35px;
  font-family: var(--font-myriadSemibold-regular);
}

.stripeForm button:hover {
  background-color: var(--mainColorHover);
}

.StripeElement {
  box-sizing: border-box;

  height: 40px;

  padding: 10px 12px;

  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;

  box-shadow: 0 1px 3px 0 #b9bdc1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #707273;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.stripeForm label {
  width: 100% !important;
}

.stripeFormText {
  font-size: 14px;
  color: #a9a6a6;
  margin-top: 20px;
  letter-spacing: 1px;
}
.stripeFormText b {
  color: var(--mainColor);
  font-family: var(--font-semibold)
}

.stripeForm .custom-alert {

  text-align: center;
  margin-top: 40px;
}

.payment-content {
  padding-top: 50px;
}

.payment-content-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 18px;
}

.payment-content-item p {
  margin-bottom: 0;
  font-size: 14px;
  color: #5b626b;
  font-family: var(--font-medium);
}

.payment-content-item span {
  color: var(--mainColor);
  font-size: 14px;
}

.payment-content-item span b {
  font-family: var(--font-medium);
  font-weight: bold;
  font-size: 18px;
}

.payment-content-footer {
  padding-top: 10px;
  margin-top: 50px;
  border-top: 1px solid #bfbfbf;
}

.payment-content-footer p {
  font-size: 13px;
  color: #808080;
}

.dispatcher-pro {
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 4px;
  background-color: #fff;
  width: 960px;
  margin: 0 auto;
}

.dispatcher-pro-title {
  width: 100%;
  font-family: var(--font-medium);
  font-size: 18px;
  color: #fff;
  font-weight: 500;
  padding: 20px;
  background-color: var(--mainColor);
}

.dispatcher-pro-content {
  width: 100%;
  background-color: #fff;
  padding: 20px;
  color: #666C73;
}

.dispatcher-pro-cta {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-family: var(--font-medium);
  font-size: 18px;
  background-color: var(--mainColor);
  width: 200px;
  height: 40px;
  margin-bottom: 20px;
}
