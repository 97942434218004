
.board-table-btn-group {
  display: flex;
  justify-content: center;
  border: 1px solid var(--mainColor);
  border-radius: 3px;
  width: fit-content;
  margin: 0 auto;
}

.board-table-btn-group-single {
  width: 26px;
}

.board-table-btn-group-icon:not(:last-child) {
  border-right: 1px solid var(--mainColor);
}
.board-table-btn-group-icon {
  padding: 5px 5px;
  margin: 1px 1px;
  color: var(--mainColor);
  text-align: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  cursor: pointer;
}
.board-table-btn-group-icon:hover {
  color: var(--mainColorActive);
}

.board-table-btn-edit {
  font-size: 14px;
  color: #6e8195;
  cursor: pointer;
}

.board-table-btn-delete {
  color: #d33232;
  font-size: 14px;
  cursor: pointer;
}

.board-table-btn-edit-lock,
.board-table-btn-delete-lock {
  color: #c1c1c1;
  cursor: inherit;
}

.board-table-btn-edit-lock:hover,
.board-table-btn-delete-lock:hover {
  color: #c1c1c1;
}

.modal-body-delete {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 60px;
}

.modal-body-delete button {
  margin: 0 80px;
  font-family: var(--font-myriadSemibold-regular);
}

.modal-body-delete-text {
  text-align: center;
  line-height: 36px;
  font-size: 22px;
  width: 100%;
  padding: 40px 0 20px 0;
}


.custom-pagination .page-item [aria-disabled="true"] {
  color: #ccc;
}


.board-table table td.data-warning {
  color: #dc3545;
}

.load-table-header {
  list-style: none;
  display: flex;
  padding: 0;
  margin-bottom: 0;
  width: 100%;
  align-items: center;
}

.load-table-header.header-first > li:nth-child(2) {
  margin-left: 0px;
}

.load-table-header li {
  margin-right: 30px;
}
.load-table-header li.exportCSV {
  margin-right: 0;
}

.load-table-header.header-second li {
  margin-right: 0px;
}
.filter_checkbox {
  margin-right: 6px;
  margin-top: 6px;
}

.exportCSV {
  justify-content: flex-end;
  display: flex;
  flex: 1;
  align-items: flex-end;
}
.exportCSV:hover {
  cursor: pointer;
}

.export-csv-image {
  height: 26px;
  margin-top: -3px;
}

.change-name {
  cursor: pointer;
}

.upload-info-text,
.upload-info-text a {
  color: #EC5A53;
  size: 12px;
  line-height: 18px;
}

.upload-info-text {
  margin-bottom: 0;
  margin-right: 30px;
}

.upload-info-text a {
  font-weight: 700;
  text-decoration: underline;
}

.upload-info-text a:hover {
  color: #EC5A53;
  text-decoration: underline;
}

.export-list-text {
  font-weight: 700;
  height: 100%;
  size: 12px;
  line-height: 18px;
  color: #535455;
  margin-right: 5px;
}

.export-list-text:hover {
  text-decoration: underline;
  cursor: pointer;
}

.img-container {
  display: flex;
  align-items: flex-end;
}

.load_details_header {
  border-radius: 3px;
  border-width: 1px;
  border-color: dimgray;
  border-style: solid;
  text-align: center;
  padding: 7px;
  background-color: whitesmoke;
  color: black;
  margin-right: 10px;
  margin-bottom: 10px;
  z-index: 100;
}

.load_details_header2 {
  border-radius: 3px;
  border-width: 1px;
  border-color: dimgray;
  border-style: solid;
  text-align: center;
  padding: 7px;
  background-color: whitesmoke;
  color: black;
  margin: 5px;
  z-index: 100;
}

.load_details_header3 {
  border-radius: 3px;
  border-width: 1px;
  border-color: dimgray;
  border-style: solid;
  text-align: center;
  padding: 7px;
  margin: 1px;
  z-index: 100;
  justify-content: flex-start;
}

.load_details-btn {
  border-radius: 3px;
  border-width: 1px;
  border-style: solid;
  text-align: center;
  padding: 8px 12px 4px 12px;
  background: var(--mainColor);
  border-color: var(--mainColor);
  color: #fff;
  margin-right: 10px;
  margin-bottom: 10px;
  font-family: var(--font-myriadSemibold-regular);
  font-size: 14px;
}

.load_details-btn:hover {
  background: var(--mainColorHover);
  border-color: var(--mainColorHover);
  color: #fff;
  cursor: pointer;
}

.load_details-btn:active,
.load_details-btn:focus {
  background: var(--mainColorActive) !important;
  border-color: var(--mainColorActive) !important;
  color: #fff !important;
}

.car_detail_box {
  display: flex;
  flex-wrap: wrap;
  margin: 10px;
}

.car_detail_box2 {
  display: flex;
  flex-grow: 1;
  flex-direction: row !important;
}

.load_details_header:hover {
  background-color: gainsboro;
  cursor: pointer;
}

div.rowContainer {
  max-width: 100%;
  align-items: left;
  vertical-align: top;
  display: flex;
}

div.rowOuter {
  display: inline-block;
  padding-left: 0%;
  padding-right: 40px;
  padding-top: 10px;
  vertical-align: top;
}

div.rowOuter ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

div.rowOuter li:hover {
  cursor: pointer;
  text-decoration: underline;
}

div.rowInner {
  display: inline-block;
  padding-left: 0%;
  padding-right: 40px;
  vertical-align: top;
  padding-top: 10px;
}

div.rowInner ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

div.rowInner li:hover {
  cursor: pointer;
  text-decoration: underline;
}

div.rowLast {
  display: inline-block;
  padding-left: 0%;
  padding-right: 40px;
  vertical-align: top;
  padding-top: 10px;
}

div.rowLastEnd {
  display: inline-block;
  padding-left: 0%;
  padding-right: 20px;
  vertical-align: top;
  padding-top: 10px;
  margin-top: 24px;
  flex-grow: 1;
}

div.rowLast ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

div.rowLast li:hover {
  cursor: pointer;
  text-decoration: underline;
}

.rowTitle {
  font-weight: bold;
  color: lightslategray; 
  padding-left: 10px
}

.rowSubtitle {
  font-weight: bold;
  color: lightslategray; 
  cursor: auto !important;
  text-decoration: none !important;
  text-shadow: none !important;
}

div.date-difference {
  background-color: gainsboro !important;
  width: fit-content !important;
  display: flex;
  vertical-align: top;
  flex-direction: row;
  justify-content: center;
  padding: 16px 30px 16px 30px;
  border-radius: 4px;
}

div.date-title {
  font-size: 10pt;
  font-weight: bold;
}

div.full-date {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: fit-content;
  text-align: left;
  color: var(--mainColorActive)
}

div.date-day {
  text-align: left;
  padding: 0px !important;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font-weight: bold; 
}

div.date-day-of-month {
  text-align: left;
  font-size: 36pt;
  font-weight: bold; 
  line-height:normal;
}

div.date-month-year-status {
  text-align: left;
  font-size: 12pt;
  padding-top: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 6px;
}

div.date-month-year {
  text-align: left;
  font-size: 12pt;
  padding-top: 0px;
  font-weight: bold;
}

div.date-status {
  text-align: left;
  font-size: 10pt;
  padding-top: 2px;
  font-weight: bold;
  color: var(--mainColorActive) !important;
}

div.middle-date {
  display: inline-block;
  align-content: center;
  text-align: center;
  vertical-align: middle;
}

div.middle-date::before {
  display: inline-block;
  padding-left: 30px;
  padding-right: 30px;
  align-content: center;
  text-align: center;
  vertical-align: middle;
  padding-top: 28px;
  height: 60px;
  font-size: 28pt;
  color: var(--mainColorActive)
}

.notdelayed {
  color: #419c5f !important;
}

.data-warning {
  color: #dc3545 !important;
}

.vertical-timeline::before {
  content: '';
  position: absolute;
  top: 0;
  left: 18px;
  height: 100%;
  width: 4px;
  background: black;
}

.link {
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
  cursor: pointer;
}

.link_direction {
  text-decoration: none;
  transition: text-decoration 0.3s ease, color 0.3s ease; /* Add a transition for text-decoration and color */
  color: #333; /* Set the default text color */
}

.link_direction:hover {
  text-decoration: underline;
  cursor: pointer;
  color: #007bff; /* Change the text color on hover */
}

.opportunities-form {
  display: flex;
  flex-direction: column;
}

.opportunities-form2 {
  display: flex;
  flex-direction: column;
}

.opportunities-form .opportunity-form-row {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.opportunities-form .opportunity-form-row2 {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
}

.opportunities-form .opportunity-form-row3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  
  border-radius: 3px;
  border-width: 1px;
  border-color: dimgray;
  border-style: solid;

  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
}

.opportunities-form .opportunity-form-row4 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  align-self: stretch;
}

.opportunities-form .opportunity-form-row4 .form-check {
  align-items: center;
  justify-content: flex-start;
  padding-left: 0px;
  height: 100%;
}

.opportunities-form .opportunity-form-column {
  display: inline-block;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  width: fit-content;
}

.accepted-button:disabled {
  background-color: #419c5f; /* Change the background color for disabled state */
  color: white; /* Change the text color for disabled state */
  cursor: not-allowed; /* Change the cursor to not-allowed */
}

.declined-button:disabled {
  background-color: #dc3545; /* Change the background color for disabled state */
  color: white; /* Change the text color for disabled state */
  cursor: not-allowed; /* Change the cursor to not-allowed */
}

.accept-button {
  background-color: #419c5f; /* Change the background color for disabled state */
  color: white; /* Change the text color for disabled state */
}

.decline-button {
  background-color: #dc3545; /* Change the background color for disabled state */
  color: white; /* Change the text color for disabled state */
}

.custom-form-control-select-locations {
  width: 100%;
  margin: 0 auto;
  margin-top: 0px;
}
.custom-form-control-select-locations._filters {
  /* height: 26px; */
  font-size: 14px;
}

.custom-form-control-select-locations._filters .css-bg1rzq-control,
.custom-form-control-select-locations._filters .css-1szy77t-control{
  /* min-height: 126px; */
  height: 126px;
}

.custom-form-control-select-locations._filters .css-1wy0on6,
.custom-form-control-select-locations._filters .css-1hwfws3,
.custom-form-control-select-locations._filters .css-1g6gooi
{
  /* height: 26px; */
  line-height: 16px;
}

.custom-form-control-select-locations .css-bg1rzq-control {
  border-radius: 0px !important;
  border-left: none !important;
  border-right: none !important;
  border-color: #ced4da !important;
  /* min-height: 36px; */
  /* height: 100% !important; */
  min-height: 39px !important;
}

.urgent-label {
  border-radius: 3px;
  border-width: 1px;
  border-color: #FF4500;
  border-style: solid;
  background-color: #FF4500;
  color: whitesmoke;
  padding-right: 2px
}

.normal-label {
  border-radius: 3px;
  border-width: 1px;
  border-color: grey;
  border-style: solid;
  background-color: grey;
  color: whitesmoke;
  padding-right: 2px;
  padding-left: 2px
}

/* label {
  font-weight: 500 !important;
  margin-left: 6px !important;
} */

/* .board-container {
  display: flex;
  width: 100%;
} */

.board-left-column {
  width: 25%; /* Fixed width for the left column */
  margin-top: 20px;
  margin-right: 20px;
  padding: 20px;
  background-color: #fff;
  position: relative;
  z-index: 0;
}

/* .board-right-column {
  flex: 1;
  width: 100%;
  background-color: #ffffff; 
} */