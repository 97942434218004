.load {
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0.629px -0.777px 35px 0px rgba(0, 0, 0, 0.16);
  padding: 20px 30px 20px 30px;
}

.load .load-back {
  position: relative;
  z-index: 1;
  margin-bottom: 10px;
  font-family: var(--font-medium);
}

.load .load-back:before {
  font-size: 14px;
  margin-right: 10px;
}

.load  .form-group {
  display: flex;
  flex-wrap: wrap;
}

.load  .form-group .invalid-feedback {
  margin-left: 170px;
}

.load .form-group label {
  flex: 0 0 auto;
  width: 170px;
  font-size: 13px;
  color: #212224;
  margin-top: 5px;
}
.load  .form-group input,
.load  .load-new-model,
.load .form-group .form-control {
  width: calc(90% - 170px);
}

.load-new-model-header {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

.load-new-model {
  width: 100%;
}

.load-new-model-form {
  display: none;
}

.load-new-model-form-active {
  display: flex;
  align-items: flex-end;
  padding-top: 16px;
}

.load-new-model-form .btn {
  flex: 0 0 auto;
  margin-left: 50px;
  margin-bottom: 17px;
  padding-left: 30px;
  padding-right: 30px;
}

.load-new-model-form label {
  width: 100px !important;
}

.load-new-model-checkbox-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 6px;
}

.load-new-model-checkbox-line  label {
  width: 77px !important;
  cursor: pointer;
}

.load-new-model-checkbox-line .custom-checkbox {
  padding-left: 0;
}

.load-new-model-checkbox-line .custom-control-label:before,
.load-new-model-checkbox-line .custom-control-label:after {
  left: inherit;
  right: 0;
  top: 0;
}


.load-status {
  padding: 4px 7px;
  text-transform: uppercase;
  border-radius: 3px;
  color: #fff;
  font-weight: bold;
  display: inline-block;
  font-size: 10px;
  line-height: 12px;
  margin-top: 7px;
  width: 100%;
  text-align: center;
}

.load-status-active {
  background-color: #58db83;
}

.load-status-disabled {
  background-color: #6c757d;
}

.load-status-alert {
  background-color: #f5b225;
}

.load-create-cancel {
  margin-right: 60px;
  display: inline-block;
  font-size: 14px;
  color: var(--mainColor);
  cursor: pointer;
}

.load-create-cancel:hover {
  text-decoration: underline;
}

.load-new-model-header-item {
  color: var(--mainColor);
  /* background-color: #f3f3f3; */
  border-radius: 4px;
  display: inline-block;
  vertical-align: middle;
  border: 1px solid #b9c0c7;
  padding: 5px 45px 5px 15px;
  position: relative;
  z-index: 0;
  font-size: 12px;
  margin-right: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  height: fit-content;
  /* line-height: 2.0; */
}

.load-new-model-header-item:hover {
  box-shadow: 0 0 3px 0px #c5c5c5;
}

.load-new-model-header-item span {
  width: 34px;
  height: 29px;
  position: absolute;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  top: 0;
  right: 0;
  border-left: 1px solid #b9c0c7;
  transition: all linear 0.2s;
  cursor: pointer;
}

.load-new-model-header-item span:hover {
  background-color: #dedfe0;
}

.load-new-model-header-item span:before,
.load-new-model-header-item span:after {
  content: '';
  width: 14px;
  height: 1px;
  background-color: var(--mainColor);
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.load-new-model-header-item span:before {
  transform: rotate(-45deg);
}

.load-new-model-header-item span:after {
  transform: rotate(45deg);
}

.load-new-model-header-item-active {
  background-color: var(--mainColor);
  color: #fff;
}

.load-new-model-header-item-active span:before,
.load-new-model-header-item-active span:after {
  background-color: #fff;
}

.load-new-model-header-item-active span:hover {
  background-color: #28588c;
;
}




.load-new-file-header-item {
  color: var(--mainColor);
  background-color: #f3f3f3;
  border-radius: 4px;
  display: inline-block;
  vertical-align: middle;
  /* border: 1px solid #b9c0c7; */
  padding: 5px 30px 5px 15px;
  position: relative;
  z-index: 0;
  font-size: 12px;
  font-weight: 600;
  margin-right: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  height: fit-content;
  /* line-height: 2.0; */
}

.load-new-file-header-item:hover {
  box-shadow: 0 0 3px 0px #c5c5c5;
}

.load-new-file-header-item span {
  --mainColor: red;
  width: 29px;
  height: 100%;
  position: absolute;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  top: 0;
  right: 0;
  /* border-left: 1px solid #b9c0c7; */
  transition: all linear 0.2s;
  cursor: pointer;
}

.load-new-file-header-item span:hover {
  background-color: #dedfe0;
}

.load-new-file-header-item span:before,
.load-new-file-header-item span:after {
  content: '';
  width: 14px;
  height: 1px;
  background-color: var(--mainColor);
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.load-new-file-header-item span:before {
  transform: rotate(-45deg);
}

.load-new-file-header-item span:after {
  transform: rotate(45deg);
}

.load-new-file-header-item-active {
  background-color: var(--mainColor);
  color: #fff;
}

.load-new-file-header-item-active span:before,
.load-new-file-header-item-active span:after {
  background-color: #fff;
}

.load-new-file-header-item-active span:hover {
  background-color: #28588c;
;
}
