/*.nav-tabs-custom-loads .nav-item {*/
/*width: 50%;*/
/*text-align: center;*/
/*}*/

/*.custom-chartjs-size-monitor canvas {*/
/*width: 100% !important;*/
/*height: 305px !important;*/
/*padding-left: 80px;*/
/*padding-right: 80px;*/
/*}*/


/*.rv-xy-plot__series--label-text {*/
/*display: none;*/
/*}*/

.rv-xy-plot__grid-lines,
.rv-xy-plot__grid-lines__line{
  display: none;
}

.rv-xy-plot {
  margin: 0 auto;
  margin-top: 20px;
}
.nav.nav-tabs.nav-tabs-custom.nav-tabs-custom-loads .nav-item {
  width: 50%;
  text-align: center;
}


.completedLoadsView {
  width: 500px;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  padding-top: 20px;
}


.completedLoadsViewItem {
  margin: 0 30px;
  position: relative;
  z-index: 1;
  padding-left: 40px;
}

.completedLoadsViewItem:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  width: 20px;
  height: 10px;
  margin-top: -5px;
}

.completedLoadsViewItem--old:before{
  background-color: rgb(18, 147, 154);
}

.completedLoadsViewItem--new:before{
  background-color: rgb(121, 199, 227);
}

/*.completedLoads .rv-xy-plot__inner .rv-xy-plot__series--label{*/
/*transform: translate(0,0) !important;*/
/*}*/


.toolTipBar {
  /* position: absolute; */
  height: auto;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 1px 2px 2px;
  z-index: 1000;
  pointer-events: none;
  border-radius: 0px;
  left: 132px;
  bottom: -72px;
  transition: left 0.1s ease-out 0s, bottom 0.1s ease-out 0s;
}

.toolTipBar .wrap {
  width: auto;
  height: auto;
  min-width: 50px;
  margin: 0px;
  padding: 5px;
  font-family: "Trebuchet MS", Helvetica, sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 14px;
  color: black;
  text-shadow: rgba(0, 0, 0, 0.1) 1px 1px 1px;
  text-align: left;
  border: 1px solid rgb(40, 187, 227);
  background: rgba(255, 255, 255, 0.9);
  text-indent: 0px;
  white-space: nowrap;
  border-radius: 0px;
  user-select: none;
}
