
.people-table-transporter.invites-table table td:first-child,
.people-table-transporter.invites-table table th:first-child {
    width: 40px;
}

.people-table-transporter.invites-table table td:nth-child(2),
.people-table-transporter.invites-table table th:nth-child(2) {
    width: 150px;
}

.people-table-transporter.invites-table table td:nth-child(3),
.people-table-transporter.invites-table table th:nth-child(3) {
    width: 100px;
}

.people-table-transporter.invites-table table td:nth-child(4),
.people-table-transporter.invites-table table th:nth-child(4) {
    width: 160px;
}

.people-table-transporter.invites-table table td:nth-child(5),
.people-table-transporter.invites-table table th:nth-child(5) {
    width: 180px;
}

.people-table-transporter.invites-table table td:nth-child(6),
.people-table-transporter.invites-table table th:nth-child(6) {
    width: 80px;
}

.people-table-transporter.invites-table table td:nth-child(7),
.people-table-transporter.invites-table table th:nth-child(7) {
    width: 80px;
}

.people-table-transporter.invites-table table td:nth-child(8),
.people-table-transporter.invites-table table th:nth-child(8) {
    width: 80px;
}

.people-table-transporter.invites-table table td:nth-child(9),
.people-table-transporter.invites-table table th:nth-child(9) {
    width: 80px;
}

.people-table-transporter.invites-table table td:last-child,
.people-table-transporter.invites-table table th:last-child{
    width: 80px;
}