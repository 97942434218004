.progress-bar {
  height: 1px;
  background-color: #007bff;
  animation: progress-animation 2s infinite linear;
  max-height: 1px;
}

@keyframes progress-animation {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.hidden {
  display: none;
}