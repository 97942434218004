.googleMapWrap {
  width: 100%;
  height: 500px;
}

.googleMapWrap > div {
  width: auto;
  height: auto;
  max-width: 100% !important;
  max-height: 100% !important;
  position: relative !important;
}


.custom-control .custom-control-inline {
  margin-right: 0;
}
