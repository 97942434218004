.board-container {
  display: flex;
  width: 100%;
  height: 100%;
  /* height: 1000px; */
  min-height: 100%;
}

.board-left-column {
  width: 25%; /* Fixed width for the left column */
  margin-top: 20px;
  margin-right: 20px;
  padding: 20px;
  background-color: #fff;
  position: relative;
  z-index: 0;
}

.board-center-column {
  width: 100%; /* Fixed width for the left column */
  height: 100% !important;
  min-height: 100% !important;
  /* margin-top: 20px;
  padding: 20px; */
  background-color: #fff;
  position: relative;
  z-index: 0;
  display: block !important;
  overflow: auto !important;
}

/* .board-right-column {
  flex: 1;
  width: 100%;
  background-color: #ffffff;
} */