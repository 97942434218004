.map {
  height: 680px;
  min-height: 680px;
}

.loads-map {
  margin-top: 20px;
  padding: 20px;
  background-color: #fff;
  position: relative;
  z-index: 0;
}

.loads-map-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
}

.loads-map-header span {
  font-size: 14px;
  cursor: pointer;
  color: var(--mainColor)
}

.loads-map-title {
  color: #5b626b;
  font-weight: bold;
  font-size: 16px;
}
.loads-map .leaflet-container {
  height: 500px;
  width: 100%;
}

.custom-marker {
  font-size: 11px;
  width: 120px;
}

.leaflet-popup-content {
  margin: 0 !important;
  padding: 15px 10px;
  width: auto !important;
}

.custom-marker-name {
  font-weight: bold;
  margin-bottom: 2px;
}

.custom-marker-code {
  margin-bottom: 2px;
  width: 180px;
}

.custom-marker-status {
  padding: 0 3px;
  color: #fff;
  border-radius: 3px;
  display: inline-block;
  margin-bottom: 2px;
}

.custom-marker-status-new {
  background-color: #58db83;
}

.custom-marker-status-picked {
  background-color: #6c757d;
}

.custom-marker-status-assigned {
  background-color: #f5b225;
}

.leaflet-popup-content {
  width: 110px !important;
}

.custom-marker-blue {
  margin-top: 2px;
  font-size: 13px;
  color: #0177ec;
}

.opportunities-marker {
  font-size: 11px;
  min-width: 220px;
}

.opportunity-name {
  margin-top: 2px;
  font-weight: 500;
  font-size: 14px;
  color: #5175A1;
}

.opportunity-phone {
  margin: 2px 0;
  font-weight: 400;
  font-size: 12px;
  color: #5175A1;
}

.opportunity-blue {
  margin-top: 2px;
  font-size: 13px;
  color: #5175A1;
}

.opportunity-dot {
  color: #5175A1;
  font-size: 14px;
  margin-right: 15px;
  /*width: 100%;*/
}

.opportunity-dot-spacer {
  height: 10px;
  border-left: 2px solid #5175A1;
  margin-left: 5px;
  margin-bottom: 3px
}

.opportunities-cars.visible {
  height: auto;
}

.opportunities-cars {
  margin-top: 5px;
  width: 100%;
  background: rgba(235, 235, 235, 0.5);
  padding: 5px;
}

.opportunities-cars p {
  margin-bottom: 5px;
}

.opportunities-cars-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
}

.opportunities-form {
  display: flex;
  flex-direction: column;
}

.opportunities-form .opportunity-form-row {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.opportunities-form input {
  width: 150px;
  margin: 5px;
  padding: 5px;
  border-radius: 3px;
  border: 1px solid #5175A1;
}

.opportunities-form button {
  flex: 1;
  margin: 5px;
  cursor: pointer;
  width: 150px;
  padding: 5px;
  border: 1px solid #5175A1;
  border-radius: 3px;
  background-color: #5175A1;
  color: white;
}

.custom-marker-red {
  margin-top: 2px;
  font-size: 13px;
  color: #ff0000;
}

.car {
  float: right;
  font-weight: bold;
  font-size: 11px;
  color: #000000;
  padding-top: 3px;
}

#pac-container {
  padding-bottom: 12px;
  margin-right: 12px;
}

.pac-controls {
  display: inline-block;
  padding: 5px 11px;
}

.pac-controls label {
  font-family: Roboto;
  font-size: 13px;
  font-weight: 300;
}

#pac-input {
  position: absolute;
  top: 10px;
  left: 50px;
  background-color: #fff;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 300;
  padding: 2px 11px;
  text-overflow: ellipsis;
  width: 200px;
  border: 1px solid;
  z-index: 10; /* Ensure it is on top of the map */
  display: none; /* Initially hidden */
  margin-top: 20px;
}

#pac-input:focus {
  border-color: #4d90fe;
}

.label-map-priority {
  background-color: #73a5c2;
  border: 1px solid #638893;
  padding: 1px 4px;
  border-radius: 3px;
  color: #FFF;
}

.opportunity-row {
  margin: 15px 0 5px 0;
  display: flex;
  min-width: 100%;
  position: relative;
  justify-content: space-between;
}

.opportunity-row-item.price {
  color: #5175A1;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
}

.opportunity-row-item.date {
  font-size: 10px;
  font-weight: 500;
  color: #333333;
  line-height: 15px;
}

.opportunity-row button {
  cursor: pointer;
  padding: 5px 0;
  border: 1px solid #EBEBEB;
  border-radius: 3px;
  background-color: #EBEBEB;
  color: #5175A1;
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
  width: 105px;
}

.opportunity-row .secondary-button {
  background: none;
  border: none;
  color: #ED5A53;
  max-width: fit-content;
  margin-left: 10px;
  padding: 0;
}

.opportunity-row .accept-button {
  background-color: #7DD78B;
  color: #ffffff;
}

.opportunity-row.buttons-container {
  padding-left: 60px;
}
