.board-container {
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 100%;
}

.board-left-column {
  width: 25%; /* Fixed width for the left column */
  margin-top: 20px;
  margin-right: 20px;
  padding: 20px;
  background-color: #fff;
  position: relative;
  z-index: 0;
}

.board-center-column {
  width: 100%; /* Fixed width for the left column */
  height: 100% !important;
  min-height: 100% !important;
  margin-top: 20px;
  padding: 10px 16px 16px 16px;
  background-color: #fff;
  position: relative;
  z-index: 0;
  display: block !important;
  overflow: auto !important;
  border-radius: 10px;
  -webkit-transition: opacity 1.5s ease, transform 0.5s ease;
  transition: opacity 1.5s ease, transform 0.5s ease;
}

/* .board-right-column {
  flex: 1;
  width: 100%;
  background-color: #ffffff; 
} */

.details-container {
  display: flex;
  height: auto; /* Adjust based on your needs */
}

.details-left-column {
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Optional: Align content vertically */
  /* background-color: #f0f0f0; */
  padding: 14px 8px 14px 8px;
  margin: 1px;
}

.details-right-column {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.details-right-top {
  display: flex;
  flex-grow: 1;
}

.details-top-row {
  flex: 1;
  /* background-color: #e0e0e0; */
  padding: 4px;
  margin: 1px;
  font-size: 18px;

  display: flex;
  align-items: center; /* Aligns content vertically */
}

.details-top-row-icon {
  width: auto;
  height: auto;
  padding: 4px 0px 4px 0px;
  margin: 1px;

  display: flex;
  justify-content: center; /* Aligns content horizontally */
  align-items: center; /* Aligns content vertically */
}

.details-bottom-row {
  height: auto;
}

.normal-text {
  color: rgba(0, 0, 0, 0.85) !important;
  text-decoration: none !important;
}

.dimmed-text {
  color: #8A98AB !important;
  text-decoration: underline !important;
  /* padding: 0px 4px 0px 4px; */
}

.link {
  /* color: #007bff !important; */
  /* text-decoration: underline !important; */
  cursor: pointer;
}

.link:hover {
  /* color: #0056b3; */
  text-decoration: underline;
}

.easy-text {
  color: #8A98AB !important;
}

.time-ago-text {
  text-align: right;
  font-size: 16px;
}

.distance-text {
  font-size: 16px;
  text-align: left;
  text-decoration: underline;
  padding-left: 10px;
}

.time-ago-row {
  height: auto;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.four-column-container {
  display: flex;
  height: 100%; /* Takes full viewport height */
}

.fixed-column {
  width: auto; /* Fixed width for columns 1, 2, and 4 */
  display: flex;
  justify-content: center;
  align-items: center; /* Centers content horizontally and vertically */
  padding: 8px;
}

.payment-fixed-column {
  width: auto; /* Fixed width for columns 1, 2, and 4 */
  display: flex;
  justify-content: center;
  align-items: center; /* Centers content horizontally and vertically */
  padding: 8px;
}

.rush-fixed-column {
  width: auto; /* Fixed width for columns 1, 2, and 4 */
  /* background-color: #f0f0f0; */
  display: flex;
  justify-content: center;
  align-items: center; /* Centers content horizontally and vertically */
}

.flexible-column {
  flex-grow: 1; /* Takes up the remaining space */
  display: flex;
  justify-content: center;
  align-items: center; /* Centers content horizontally and vertically */
  flex-direction: column; /* Stack rows vertically */
  margin: 0px 16px 0px 16px;
}

.payment-box {
  width: auto;
  height: auto;
  padding: 2px 8px 2px 8px;
  border: 2px solid;
  border-radius: 5px; /* Makes the border rounded */
  display: flex;
  justify-content: center;
  align-items: center; /* Centers text inside the circle */
  text-align: center;
  font-size: 18px;
}

.flexible-row {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rush-color {
  color: #4382F7;
}

.top-row {
  justify-content: center;
  align-items: flex-end; /* Aligns content to the center bottom */
  font-size: 42px;
  font-weight: bold;
  padding-top: 8px;
  line-height: 42px;
}

.bottom-row {
  justify-content: center;
  align-items: flex-start; /* Aligns content to the center top */
  /* background-color: #c0c0c0; */
  font-size: 18px;
}

.icon-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  -webkit-transition: background-color 0.3s ease, transform 0.3s ease;
  transition: background-color 0.3s ease, transform 0.3s ease;
  width: 100%;
  height: 100%;
}

.icon-button:hover {
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.icon-button:focus {
  outline: none;
}

.load-details-container {
  flex-grow: 1;
  padding: 10px;
  min-height: 100%;
  margin-right: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; /* Optional: Adds "..." if content overflows */
}

.offer-details-container {
  width: auto;
  min-height: 100%;
  padding-left: 32px;
}

.collapsed {
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.expanded {
  opacity: 1;
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
}

.main-details-container {
  display: flex;
  min-height: 100%;
}

/* -------------------------------------------------------------------------------------------------- */

.board-full-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%; /* Ensures the container takes full height */
}

.board-full-first-row {
  display: flex;
  width: 100%;
  align-items: center; /* Vertically centers the content */
  padding-top: 15px; /* Adds some space to the top */
}

.board-full-first-row > div {
  margin-right: 8px; /* Adds some space between divs */
}

.board-full-flex-grow {
  flex-grow: 1; /* Takes up remaining space in the first row */
  padding-right: 20px; /* Adds some space to the right */
}

.board-full-second-row {
  flex: 1; /* Takes up remaining height */
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center; /* Centers content within the second row */
}

.title {
  font-size: 24px;       /* Larger font size for the title */
  font-weight: bold;     /* Bold text */
  color: #333;           /* Darker color for the title */
  margin-bottom: 8px;    /* Optional space below the title */
  text-align: left;      /* Align text to the left */
}

.subtitle-text {
  color: #8A98AB !important;
  font-size: 18px;
  font-weight: bold;
}

/* -------------------------------------------------------------------------------------------------- */

/* The container is a grid with two columns */
.vehicle-container {
  display: grid;
  grid-template-columns: 1fr 100px;
  height: 100%;
  flex-grow: 1;
  margin-top: 12px;
}

/* The left column takes full space minus the 100px of the right column */
.vehicle-left-column {
  display: grid;
  grid-template-rows: repeat(3, 1fr); /* Divides into 3 equal rows */
  padding: 5px 5px 5px 0px;
}

/* Aligns text to the left */
.vehicle-row {
  text-align: left;
  vertical-align:text-bottom;
  display: flex;
  align-items: flex-end; /* Align text at the bottom */
}

/* The right column has a fixed size and centers the image */
.vehicle-right-column {
  display: flex;
  justify-content: center;
  align-items: center;
}

.vehicle-image {
  width: 100px;
  height: 100px;
  /* border: #A3C1F0 1px solid; */
  border-radius: 6px;
  background-color: #F0F8FF;
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.1);
  object-fit: cover;
}

@media (max-width: 1600px) {
  .vehicle-image {
    display: none;
  }
}

.vehicles-scrollable-container {
  max-height: 280px; 
  /* Limit the height to 300px */
  overflow-y: auto;  /* Enable scrolling if content exceeds 300px */
  padding-right: 8px;
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent; /* For Firefox */
}

/* Chrome, Safari, Edge */
.vehicles-scrollable-container::-webkit-scrollbar {
  width: 8px;
}

.vehicles-scrollable-container::-webkit-scrollbar-track {
  background: transparent; /* Transparent background */
}

.vehicles-scrollable-container::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.1); /* Dark gray thumb with some transparency */
  border-radius: 10px; /* Rounded corners */
  border: 2px solid transparent; /* Add space around the scrollbar thumb */
}

.vehicles-scrollable-container::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.2); /* Slightly darker on hover */
}

.vehicles-non-scrollable-container {
  max-height: none;  /* No height limit */
  overflow: visible; /* No scrolling */
  padding-right: 8px;
  max-width: 512px;
}

.vehicles-details-container {
  /* Your existing styling for each vehicle detail */
  padding: 10px; /* Add some spacing if needed */
}

/* -------------------------------------------------------------------------------------------------- */

.offer-form-container {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.offer-form-row {
  margin-bottom: 20px;
}

.offer-label {
  display: block;
  margin-bottom: 5px;
  font-size: 18px;
}

.offer-input {
  width: 100%;
  padding: 10px 10px 10px 22px;
  border: 1px solid #8A98AB;
  border-radius: 6px;
  box-sizing: border-box;
  font-size: 18px;
}

.offer-input:disabled {
  width: 100%;
  padding: 10px 10px 10px 22px;
  border: 1px solid #ccc;
  border-radius: 6px;
  box-sizing: border-box;
  font-size: 18px;
  background-color: #f0f0f0;
  color: #999;
  cursor: not-allowed;
}

.offer-form-row-two-columns {
  display: flex;
  gap: 20px;
}

.offer-form-column {
  width: 50%;
}

.offer-price-container {
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
}

.offer-price-icon {
  color: #6c757d;
}

.offer-price-prefix {
  margin-left: 5px;
  font-size: 18px;
  font-weight: bold;
}

.price-input {
  flex: 1;
  padding-left: 20px;
  padding-right: 80px;
}

.offer-price-text {
  margin-left: auto;
  font-size: 16px;
  color: #6c757d;
}

.offer-tip {
  font-size: 14px;
  color: #6c757d;
  margin-top: 5px;
}

.offer-form-checkbox-row {
  display: flex;
  align-items: center;
}

.offer-checkbox {
  margin-right: 10px;
}

.offer-checkbox-label {
  font-size: 16px;
  margin-bottom: 0 !important;
}

.offer-button-container {
  display: flex;
  gap: 10px; 
}

.offer-submit-btn {
  width: 100%;
  padding: 12px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 30px;
  font-size: 18px;
  cursor: pointer;
}

.offer-submit-btn:hover {
  background-color: #0056b3;
}

.offer-submit-btn:disabled {
  background-color: #ccc;
  color: #666;
  cursor: not-allowed;
}

.offer-submit-btn-accept {
  width: 100%;
  padding: 12px;
  background-color: #28a745;
  color: #fff;
  border: none;
  border-radius: 30px;
  font-size: 18px;
  cursor: pointer;
}

.offer-submit-btn-accept:hover {
  background-color: #218838;
}

.offer-submit-btn-decline {
  width: 100%;
  padding: 12px;
  background-color: #dc3545;
  color: #fff;
  border: none;
  border-radius: 30px;
  font-size: 18px;
  cursor: pointer;
}

.offer-submit-btn-decline:hover {
  background-color: #c82333;
}