.load {
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0.629px -0.777px 35px 0px rgba(0, 0, 0, 0.16);
  padding: 20px 30px 20px 30px;
}

.load .load-back {
  position: relative;
  z-index: 1;
  margin-bottom: 10px;
}

.load .load-back:before {
  font-size: 14px;
  margin-right: 10px;
}

.load  .form-group {
  display: flex;
  flex-wrap: wrap;
}

.load  .form-group .invalid-feedback {
  margin-left: 170px;
}

.load .form-group label {
  flex: 0 0 auto;
  width: 170px;
  font-size: 13px;
  color: #212224;
  margin-top: 5px;
}
.load  .form-group input,
.load  .load-new-model,
.load .form-group .form-control,
.load .form-group .select-wrap{
  width: calc(90% - 170px);
}

.load .react-datepicker-wrapper {
  width: calc(90% - 170px) !important;
}


.custom-form-group .form-group {
  width: calc((90% - 170px) / 4 - 10px );
  margin-right: 13px;
}

.custom-form-group .form-group:last-child {
  margin-right: 0;
}

.custom-form-group .form-group input {
  width: 100% !important;
}

.load .react-datepicker-wrapper input {
  width: 100% !important;
}

.load-new-model-header {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

.load-new-model {
  width: 100%;
}

.load-new-model-form {
  display: none;
}

.load-new-model-form-active {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  padding-top: 16px;
}

.load-new-model-form .btn {
  flex: 0 0 auto;
  margin-left: 50px;
  margin-bottom: 17px;
  padding-left: 30px;
  padding-right: 30px;
}

.load-new-model-form label {
  width: 100px !important;
}

.load-new-model-form .select-wrap {
  width: calc(100% - 100px) !important;
}

.load-new-model-checkbox-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 6px;
}

.load-new-model-checkbox-line  label {
  width: 77px !important;
  cursor: pointer;
}

.load-new-model-checkbox-line .custom-checkbox {
  padding-left: 0;
}

.load-new-model-checkbox-line .custom-control-label:before,
.load-new-model-checkbox-line .custom-control-label:after {
  left: inherit;
  right: 0;
  top: 0;
}

.load-status {
  padding: 4px 7px;
  text-transform: uppercase;
  border-radius: 3px;
  color: #fff;
  font-weight: bold;
  display: inline-block;
  font-size: 10px;
  line-height: 12px;
  margin-top: 7px;
  width: 100%;
  text-align: center;
}

.load-status-active {
  background-color: #58db83;
}

.load-status-disabled {
  background-color: #6c757d;
}

.load-status-alert {
  background-color: #f5b225;
}

.load-create-cancel {
  margin-right: 60px;
  display: inline-block;
  font-size: 14px;
  color: var(--mainColor);
  cursor: pointer;
  font-family: var(--font-myriadSemibold-regular);
}

.load-create-cancel:hover {
  text-decoration: underline;
}

.load-new-model-header-item {
  color: var(--mainColor);
  background-color: #f3f3f3;
  border-radius: 4px;
  display: inline-block;
  vertical-align: middle;
  border: 1px solid #b9c0c7;
  padding: 5px 45px 5px 15px;
  position: relative;
  z-index: 0;
  font-size: 12px;
  margin-right: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  font-family: var(--font-medium);
}

.load-new-model-header-item  div{
  position: relative;
  z-index: 5;
}

.load-new-model-add {
  font-family: var(--font-medium) !important;
  padding-left: 35px;
  padding-right: 35px;
  font-size: 12px !important;
}

.load-new-model-header-item:hover {
  box-shadow: 0 0 3px 0px #c5c5c5;
}

.load-new-model-form-active .custom-btn {
  font-size: 12px !important;
  font-family: var(--font-medium) !important;

}

.load-new-model-header-item span {
  width: 34px;
  height: 29px;
  position: absolute;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  top: 0;
  right: 0;
  border-left: 1px solid #b9c0c7;
  transition: all linear 0.2s;
  cursor: pointer;
  z-index: 5;
}

.load-new-model-header-item span:hover {
  background-color: #dedfe0;
}

.load-new-model-header-item span:before,
.load-new-model-header-item span:after {
  content: '';
  width: 14px;
  height: 1px;
  background-color: var(--mainColor);
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.load-new-model-header-item span:before {
  transform: rotate(-45deg);
}

.load-new-model-header-item span:after {
  transform: rotate(45deg);
}

.load-new-model-header-item-active {
  background-color: var(--mainColor);
  color: #fff;
}

.load-new-model-header-item-active span:before,
.load-new-model-header-item-active span:after {
  background-color: #fff;
}

.load-new-model-header-item-active span:hover {
  background-color: #28588c;
}

.load-new-model-header-item-error {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  margin: 0;
  padding: 0;
  border-radius: 3px;
  box-shadow: 0 0 0 0.1rem rgba(216, 15, 34, 0.46);
}

.load-custom-select,
.load-new-model-form .form-control {
  width: calc(100% - 100px) !important;
}

.load-custom-select-disabled {
  pointer-events: none;
  opacity: 0.45;
}

.load-select-drivers option:first-child {
  display: none;
}

.destinationPhone + .invalid-feedback{
  margin-top: -17px;
}


.custom-form-group .form-group .invalid-feedback {
  width: 100%;
  margin-left: 0 !important;
}

.custom-form-group .form-group input::placeholder {
  color: #c1c1c1;
}


.custom-control-label {
  cursor: pointer;
}

.form-control-error,
.form-control-select-error{
  border-color: #dc3545;
}

.custom-select-error-wrap.form-control-error > div {
  border-color: #dc3545;
}

.parsePDFerror {
  font-size: 12px;
  color: #dc3545;
}

.select-car-model-error {
  font-size: 14px;
  display: block;
  margin-top: 10px;
}


.load-new-model-form-info {
  font-size: 14px;
  margin-bottom: 0;
  margin-left: 30px;
}

.load-new-model-form-info:before {
  content: 'i';
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: var(--mainColor);
  color: #fff;
  text-align: center;
  line-height: 12px;
  font-size: 10px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}


.load-new-model-form > .row {
  width: calc(100% - 50px - 112px);
}

.load-new-model-form-footer {
  display: flex;
  padding-top: 10px;
  flex-wrap: wrap;
}

.load-new-model-form-footer .custom-btn {
  margin: 0 !important;
}


.load-new-model-form-footer  .errorMsg {
  width: 100%;
}
