.profile-header {
    border-radius: 4px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0.629px -0.777px 35px 0px rgba(0, 0, 0, 0.16);
    padding: 15px 30px;
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.profile-info {
    max-width: 60%;
    display: flex;
    justify-content: space-between;
}

.profile-card {
    width: 155px;
    height: 150px;
    overflow: hidden;
}

.profile-info-wrap {
    display: flex;
    margin-bottom: 15px;
}

.profile-subtitle {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #5C626A;
    margin-bottom: 20px;
}

.profile-info-wrap input {
    height: 40px;
}

.profile-contacts {
    font-size: 14px;
    line-height: 21px;
    color: #333333;
}

.profile-edit-wrap {
    margin-left: 30px;
    width: 450px;
    height: 32px;
}

.profile-edit-title {
    margin-left: 30px;
    color: #333333;
    font-size: 14px;
}

.profile-edit-checkbox {
    margin-left: 30px;
    display: flex;
}

.profile-edit-checkbox-group {
    width: 100%;
}

.profile-edit-check {
    padding: 29px 0 0 23px;
}

.profile-dispatcher {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #5175A1;
    margin-bottom: 10px;
}

.profile-language-wrap {
    display: flex;
}

.profile-language-wrap > div {
    padding-right: 10px;
}

.profile-stars {
    width: 155px;
    text-align: center;
    margin-right: 25px;
}

.dispatcher-info {
    border-radius: 4px;
    padding: 15px 30px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0.629px -0.777px 35px 0px rgba(0, 0, 0, 0.16);
}

.dispatcher-title {
    font-size: 18px;
    color: #5b626b;
    width: 100%;
    margin-bottom: 15px;
    font-family: var(--font-medium);
}

.dispatcher-title img {
    margin: -2px 13px 0 0;
}

.dispatcher-subtitle {
    font-size: 18px;
    color: #333333;
    margin-bottom: 30px;
}

.dispatcher-form-item {
    display: flex;
    justify-content: space-between;
}

.dispatcher-title-wrap {
    font-size: 13px;
    color: #333333;
    margin-bottom: 25px;
    padding-top: 9px;
    display: flex;
    width: 20%;
}

.dispatcher-form-title img {
    margin: -3px 14px 0 0;
}

.dispatcher-input-wrap {
    width: 80%;
}

.dispatcher-info-cancel {
    margin-right: 60px;
    display: inline-block;
    font-size: 14px;
    color: var(--mainColor);
    cursor: pointer;
    font-family: var(--font-myriadSemibold-regular);
}

.dispatcher-info-cancel:hover {
    text-decoration: underline;
}

.dispatcher-info-footer {
    padding-top: 25px;
    margin-top: 14px;
    border-top: 1px solid #b9bec3;
}

.dispatcher-info-footer input:checked + label {
    opacity: 0.5;
}

.form-select-wrap {
    margin-bottom: 16px;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
.ant-select-selector:global {
    height: 38px !important;
}

.ant-select:global {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum', "tnum";
    position: relative;
    display: inline-block;
    cursor: pointer;
    width: 100% !important;
    height: 40px !important;
    font-weight: 400 !important;
    color: #495057 !important;
    background-color: #fff !important;
    background-clip: padding-box !important;
    border: 1px solid #ced4da !important;
    border-radius: .25rem !important;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out !important;
}

.ant-select-selector:global {
    padding-top: 4px;
    border: none !important;

}

.footer-wrapper {
    border-radius: 4px;
    background-color: rgb(255, 255, 255);
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    height: 145px;
}

.footer-info {
    display: flex;
}

.footer-content {
    max-width: 650px;
    margin-left: 40px;
    padding: 15px 30px;
}

.footer-content span {
    font-size: 23px;
}

.footer-content b {
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    color: #5175A1;
}

.footer-button-wrap {
    padding: 40px 33px;
}

.footer-button {
    width: 150px;
    height: 55px;
    background: #59B9DF;
    border: 2px solid #59B9DF;
    border-radius: 4px;
}

.footer-button:hover {
    background: #59B9DF;
}

.mb-15 {
    margin-bottom: 15px;
}

.d-f {
    display: flex;
}
