.client-card {
  border-radius: 4px;
  box-shadow: 0.629px -0.777px 35px 0px rgba(0, 0, 0, 0.16);
  width: calc(25% - 15px);
  margin-right: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  background-color: #fff;
  position: relative;
  z-index: 1;
  padding-bottom: 60px;
}

.client-card:nth-child(4n+4) {
  margin-right: 0;
}

.client-card-header {
  position: relative;
  z-index: 1;
}

.client-card-header-background img {
  width: 100%;
  height: 151px;
  object-fit: cover;
  position: relative;
  z-index: 1;
}

.client-card-header:before {
  content: '';
  display: block;
  width: 100%;
  height: 151px;
  background-color: #4875a5;
  opacity:  0.72;
  z-index: 5;
  position: absolute;
  top: 0;
}

.client-card-header-edit {
  position: absolute;
  cursor: pointer;
  top: 10px;
  right: 15px;
  z-index: 10;
  font-size: 13px;
  font-weight: bold;
  transition: all linear 0.2s;
  color: #fff;
  letter-spacing: 1px;
  font-family: var(--font-semibold);
}

.client-card-header-edit:hover {
  color: #fff;
  text-decoration: underline;
}

.client-card-header-logo {
  position: absolute;
  border-radius: 50%;
  bottom: -44px;
  left: 0;
  right: 0;
  margin: 0 auto;
  background-color: #fff;
  height: 88px;
  width: 88px;
  box-shadow: -1px 1.732px 3.8px 0.2px rgba(29, 34, 49, 0.25);
  z-index: 10;
}

.client-card-header-logo img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: calc(100% - 30px);
  max-height: calc(100% - 30px);
  width: auto;
  height: auto;
  margin: auto;
}

.client-card-list {
  list-style: none;
  text-align: center;
  padding-top: 64px;
  padding-left: 15px;
  padding-right: 15px;
}

.client-card-list li:nth-child(3n+3){
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.client-card-list li:last-child {
  margin-top: 20px;
}

.client-card-name {
  font-size: 18px;
  font-weight: 500;
  color: #212224;
  line-height: 26px;
  margin-bottom: 10px;
  font-family: var(--font-semibold);
}

.client-card-text {
  line-height: 30px;
  font-size: 13px;
  color: #212224
}

.client-card-footer {
  border-top: 1px solid #e1e2e5;
  display: flex;
  padding-top: 20px;
  justify-content: center;
  position: absolute;
  bottom: 20px;
  background-color: #fff;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: calc(100% - 40px);
}

@media(max-width: 1500px) {
  .client-card {
    width: calc(33.3333333% - 20px);
    margin-right: 30px;
  }

  .client-card:nth-child(4n+4) {
    margin-right: auto;
  }

  .client-card:nth-child(3n+3) {
    margin-right: 0px;
  }
}
