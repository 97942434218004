.settings-payments {
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0.629px -0.777px 35px 0 rgba(0, 0, 0, 0.16);
  padding: 15px 30px;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.settings-info {
  border-radius: 4px;
  padding: 15px 30px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0.629px -0.777px 35px 0 rgba(0, 0, 0, 0.16);
}

.settings-info .form-group label {
  font-size: 14px;
}

.settings-info .form-group a {
  pointer-events: none;
}

.settings-title {
  font-size: 18px;
  color: #5b626b;
  width: 100%;
  margin-bottom: 15px;
  font-family: var(--font-medium);
}

.settings-subtitle {
  font-size: 14px;
  color: #5b626b;
  width: 100%;
  margin-bottom: 15px;
  font-family: var(--font-medium);
}

.settings-payments-text {
  padding-right: 200px;
  width: calc(100% - 600px);
  font-size: 14px;
  color: #5b626b;
  margin-bottom: 0;
}

.settings-payments-text b {
  font-weight: bold;
}

.settings-payments-text span {
  font-size: 12px;
  cursor: pointer;
  color: var(--mainColor)
}

.settings-payments-text span:hover {
  text-decoration: underline;
}

.settings-info .form-group {
  display: flex;
  flex-wrap: wrap;
}

.settings-info .form-group label:not(.custom-control-label) {
  width: 180px;
  margin-top: 7px;
  text-align: right;
  padding-right: 20px;
}

.settings-info .custom-control {
  padding-left: 12px;
  padding-top: 7px;
}

.settings-info .custom-control .form-control{
  width: 100%;
}

.settings-info .custom-control-label:after,
.settings-info .custom-control-label:before {
  top: 2px;
}

.settings-info .form-control,
.settings-info .form-group input {
  width: calc(100% - 200px);
}

.settings-info .invalid-feedback {
  width: 100%;
  padding-left: 180px;
}

.settings-info .settings-info-card,
.settings-info .settings-info-email {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-between;
}

.settings-info .settings-info-email .form-group {
  width: calc(100% - 200px);
}

.settings-info-card-note {
  padding-top: 20px;
  font-size: 14px;
  max-width: 60%;
}

.settings-info-card-note:before,
.settings-info-email p:before {
  content: 'i';
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: var(--mainColor);
  color: #fff;
  text-align: center;
  line-height: 12px;
  font-size: 10px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}

.settings-info-cancel {
  margin-right: 60px;
  display: inline-block;
  font-size: 14px;
  color: var(--mainColor);
  cursor: pointer;
  font-family: var(--font-myriadSemibold-regular);
}

.settings-info-cancel:hover {
  text-decoration: underline;
}

.settings-info-footer {
  padding-top: 25px;
  margin-top: 14px;
  border-top: 1px solid #b9bec3;
}

.settings-info-footer input:checked + label {
  opacity: 0.5;
}

.settings-info-email-note {
  margin-left: 180px;
  padding-left: 20px;
  width: calc(100% - 200px - 180px);
  position: relative;
  z-index: 1;
  font-size: 14px;
}

.settings-info-email-note:before {
  top: 7px;
  left: 0;
  position: absolute;
}

.settings-info-email,
.settings-info-card {
  border-bottom: 1px solid #ccc;
  margin-bottom: 40px;
  padding-bottom: 20px;
}

.setting-upload-files {
  border-top: 1px solid #ccc;
  margin-top: 20px;
  padding: 20px 0 10px 0;
}

.settings-info-card-view {
  width: 300px;
  height: 180px;
  background: linear-gradient(var(--mainColor), #2b3a4a);
  margin-right: 50px;
  border-radius: 5px;
  font-size: 16px;
  color: #fff;
  text-align: center;
  position: relative;
  z-index: 1;
  padding: 10px 20px;
}


.settings-info-card-view img {
  width: 46px;
  height: 26px;
  position: absolute;
  top: 50px;
  left: 20px;
  display: block;
}


.settings-info-card-view-card-number {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px;
}

.settings-info-card-view-card-name {
  text-align: right;
}

.settings-info-card-view-card-number p {
  width: 100%;
  text-align: left;
  margin: 0;
  margin-bottom: 8px;
  font-size: 13px;
}

.settings-info-card-view-card-number span {
  letter-spacing: 1px;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
}

.settings-info-card-new {
  width: calc(100% - 350px - 280px);
}

.settings-info-card-new form {
  display: flex;
  justify-content: space-between;
}

.settings-info-card-new .StripeElement {
  width: calc(100% - 200px);
  height: 36px;
  padding: 8px 12px;
}

.card-warning .StripeElement {
  border: 1px solid red;
  border-radius: 4px 4px 4px 4px;
}

.addressesForm {
  width: 100%;
}

.field__wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  margin: 15px 0;
  /*text-align: center;*/
}

.field__file {
  opacity: 0;
  visibility: hidden;
  position: absolute;
}

.field__file-wrapper {
  width: 400px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.field__file-fake {
  width: calc(100% - 130px);
  display: -webkit-box;
  display: -ms-flexbox;
  color: #33333380;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 7px 15px;
  border: 1px solid #c7c7c7;
  border-radius: 4px 0 0 4px;
  border-right: none;
}

.field__file-button {
  background: #4875a5;
  border-color: #2b5582;
  position: absolute;
  top: 0;
  left: 250px;
  color: #fff;
  width: 130px;
  padding: 8px 0;
  font-weight: 500;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 14px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
}

.input-container {
  position: relative;
  flex: 2;
}

.names-container {
  flex: 1.3;
}

.warning {
  margin-left: 20px;
  color: #ED5A53;
  font-size: 14px;
  line-height: 21px;
}

.names-container .file-name {
  width: fit-content;
  position: relative;
  color: #5175A1;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
}

.delete-icon {
  position: absolute;
  color: #ED5A53;
  top: 0;
  right: -10px;
  cursor: pointer;
}
